import {Route, Routes} from "react-router-dom";
import ViewPhotoPage from "./page/ViewPhotoPage";
import PageNotFoundPage from "./page/PageNotFoundPage";
import HomePage from "./page/HomePage";

function App() {
  return (
      <Routes>
          <Route index path="/v/:transactionId" element={<ViewPhotoPage/>}/>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/*" element={<PageNotFoundPage/>}/>
      </Routes>
  );
}

export default App;
