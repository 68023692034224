/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import React, {useEffect, useState} from 'react';
import ViewPhoto from "../components/viewPhoto/ViewPhoto";
import {useNavigate, useParams} from "react-router-dom";
import BaseLayout from "../components/layout/BaseLayout";
import axios from "axios";

const ViewPhotoPage = () => {
    const { transactionId } = useParams();
    const navigate = useNavigate();
    const [photoData, setPhotoData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPhotoData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_REMOTE_HOST}/api/v1/qr/${transactionId}`, { withCredentials: true });
                if (response.data) {
                    setPhotoData(response.data);
                } else {
                    navigate('/404');
                }
                setLoading(false);
            } catch (err) {

                setError(err);
                setLoading(false);
                navigate('/404');
            }
        };

        if (transactionId) {
            fetchPhotoData();
        }
    }, [transactionId, navigate]);


    return (
        <BaseLayout>
            <ViewPhoto id={transactionId} originalUrl={photoData?.data?.transactionOriginalImageName} imageUrl={photoData?.data?.transactionImageName} videoUrl={photoData?.data?.transactionVideoName} createAt={photoData?.data?.transactionCreatedAt}/>
        </BaseLayout>

    );
};

export default ViewPhotoPage;