/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import React from 'react';
import BaseLayout from "../components/layout/BaseLayout";


const HomePage = () => {
    return (
        <BaseLayout>
            <div className="min-h-screen flex flex-col justify-center items-center space-x-2">
                <div className="p-3">
                    <img className="w-24 h-auto" src={require('../assets/images/logo_kodak_red.png')} alt="Kodak Logo"/>
                    <span className="font-semibold">가까운 코닥 포토점에서 추억을 만들어 보세요.</span>
                </div>
            </div>
        </BaseLayout>
    );
};

export default HomePage;