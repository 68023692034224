/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import React from 'react';

const BaseLayout = ({children}) => {
    return (
        <div className="bg-[#fdb913] min-h-screen flex flex-col">
            {children}
        </div>
    );
};

export default BaseLayout;