/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import React from 'react';
import {CircleAlert} from "lucide-react";
import BaseLayout from "../components/layout/BaseLayout";

const PageNotFoundPage = () => {
    return (
        <BaseLayout>
            <div className="min-h-screen flex justify-center items-center space-x-2">
                <CircleAlert size={20}/>
                <span className="font-semibold text-xl">링크가 존재하지 않습니다.</span>
            </div>
        </BaseLayout>
    );
};

export default PageNotFoundPage;