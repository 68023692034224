/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import React, {memo, useCallback} from 'react';
import {Camera, CircleHelp, FileArchive, Image, MessageCircleWarning, Package, Video} from "lucide-react";
import axios from "axios";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

function getMimeType(fileUrlName) {
    const extension = fileUrlName.split('.').pop().toLowerCase(); // 파일 확장자 추출
    switch (extension) {
        case 'jpg':
        case 'jpeg':
            return 'image/jpg';
        case 'png':
            return 'image/png';
        case 'gif':
            return 'image/gif';
        case 'webp':
            return 'image/webp';
        case 'mp4':
            return 'video/mp4';
        case 'mov':
            return 'video/quicktime';
        case 'avi':
            return 'video/x-msvideo';
        default:
            return 'application/octet-stream'; // 알 수 없는 파일 타입
    }
}


const ViewPhoto = memo(({ imageUrl, videoUrl, originalUrl, createAt }) => {

    const handleDownload = useCallback(async (fileUrlName) => {
        try {
            const response = await fetch("https://kr.object.ncloudstorage.com/new-kodak/" + fileUrlName);
            const blobData = await response.blob();
            const mimeType = getMimeType(fileUrlName);
            const blob = new Blob([blobData], { type: mimeType });

            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', fileUrlName);
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Failed to download the file:', error);
        }
    }, []);



    return (
        <>
            <div className="flex flex-col items-center justify-center p-5">
                <img className="w-24 h-auto" src={require('../../assets/images/logo_kodak_red.png')} alt="Kodak Logo"/>
            </div>
            <div className="bg-[#222222] p-5 grid grid-cols-3 gap-4">
                <div className="flex flex-col justify-center items-center text-center gap-1.5 cursor-pointer" onClick={() => handleDownload(originalUrl)}>
                    <FileArchive size={25} strokeWidth={1.6} color={"#fff"}/>
                    <span className="text-white font-semibold text-[0.8rem]">원본 사진 다운로드</span>
                </div>
                <a href={"https://kr.object.ncloudstorage.com/new-kodak/"+imageUrl} target="_blank" className="flex flex-col justify-center items-center text-center gap-1.5 cursor-pointer" onClick={() => handleDownload(imageUrl)}>
                    <Image size={25} strokeWidth={1.6} color={"#fff"}/>
                    <span className="text-white font-semibold text-[0.8rem]">사진 다운로드</span>
                </a>
                <div className="flex flex-col justify-center items-center text-center gap-1.5 cursor-pointer" onClick={() => handleDownload(videoUrl)}>
                    <Video size={25} strokeWidth={1.6} color={"#fff"}/>
                    <span className="text-white font-semibold text-[0.8rem]">동영상 다운로드</span>
                </div>
            </div>
            <div className="flex flex-row justify-center items-center text-center gap-1 cursor-pointer py-4 pb-1">
                <CircleHelp size={14}/>
                <p className="font-semibold text-sm">24시간 동안 확인 할 수 있어요 :)</p>
            </div>
            <div className="flex flex-row justify-center items-center text-center gap-1 cursor-pointer py-4 pt-2">
                <Camera size={14}/>
                <p className="font-semibold text-sm">{formatDate(createAt)}</p>
            </div>
            <div className="px-10 pb-8 flex flex-col items-center justify-center gap-3">

                <div className="">
                    {imageUrl && <img src={`https://kr.object.ncloudstorage.com/new-kodak/${imageUrl}`} alt="Kodak"/>}
                </div>

                <div className="">
                    {videoUrl &&
                        <video autoPlay loop muted playsInline>
                            <source src={`https://kr.object.ncloudstorage.com/new-kodak/${videoUrl}`} type="video/mp4"/>
                        </video>
                    }
                </div>
            </div>
        </>
    );
});

export default ViewPhoto;
